<i18n>
{
  "en": {
    "anonymous": "Anonymous"
  },
  "fr": {
    "anonymous": "Anonyme"
  }
}
</i18n>

<template>
  <v-list-tile
    :key="item.__uid__"
    :ripple="clickable"
    v-bind="props"
    class="w-comments-threads-list-item"
    v-on="listPropsEvents"
  >
    <v-list-tile-content>
      <v-list-tile-title class="grey--text caption">
        <span>{{ userName }} · {{ formatDateHour(item.createdAt) }}</span>
        <v-icon
          v-if="clickable"
          class="grey--text"
          v-text="'keyboard_arrow_down'"
        />
      </v-list-tile-title>
      <v-list-tile-sub-title class="grey--text">
        <w-item-summary
          :is-too-important="autoReduce === false && summaryTooImportant"
          :summary="summary"
        />
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
import $get from 'lodash.get'
import WItemSummary from './WItemSummary'
import formatDate from '~/mixins/formatDate'

export default {
  components: {
    WItemSummary,
  },
  mixins: [formatDate],
  props: {
    autoReduce: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    listPropsEvents() {
      if (this.clickable) {
        return {
          click: (e) => {
            this.$emit('open', e)
          },
        }
      }

      return null
    },
    summary() {
      const { content } = this.item

      if (this.autoReduce && this.summaryTooImportant) {
        return this.summaryTooTall
          ? content
              .split(/\r\n|\r|\n/gi)
              .splice(0, 6)
              .map((s, i) => {
                return i === 0 ? s : '\n' + (i === 4 ? s + '...' : s)
              })
              .join('')
          : content.substr(0, 137) + '...'
      }

      return content
    },
    summaryTooImportant() {
      return this.summaryTooTall || this.summaryTooLong
    },
    summaryTooTall() {
      const { content } = this.item

      return content.split(/\r\n|\r|\n/gi).length > 6
    },
    summaryTooLarge() {
      const { content } = this.item

      return content.length > 140
    },
    userName() {
      return $get(this.item.poster, 'name', this.$t('anonymous'))
    },
  },
}
</script>

<style lang="scss">
.w-comments-threads-list-item {
  .v-list {
    &__tile {
      display: block;
      min-height: 72px;
      height: auto;
      align-items: flex-start;
      border-bottom: 2px #f7f7f7 solid;
      padding-bottom: 8px;
      padding-top: 8px;

      &::first-child {
        border-top: none;
      }

      &::last-child {
        border-bottom: none;
      }

      &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        justify-content: space-between;
      }
    }
  }
}
</style>
