<i18n>
{
  "en": {
    "filter_date": "Filter by date",
    "all_contents": "All content"
  },
  "fr": {
    "filter_date": "Filtrer par date",
    "all_contents": "Tous les contenus"
  }
}
</i18n>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    min-width="290px"
    attach="#w-filters"
    full-width
    relative
    offset-y
    lazy
  >
    <v-text-field
      slot="activator"
      v-model="date"
      :label="$t('filter_date')"
      prepend-inner-icon="event"
      color="accent"
      hide-details
      readonly
      solo
      flat
      @change="handleSave"
    />
    <v-date-picker
      v-model="date"
      :allowed-dates="allowedDates"
      type="month"
      color="primary"
      locale="fr"
      scrollable
      current
      @input="handleSave"
    >
      <v-spacer />
      <v-btn flat @click="handleSave()">
        {{ $t('all_contents') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'

import { getConfig } from '~/utils/voicer'

const datePickerStartAt = getConfig('datePickerStartAt', null)

export default {
  data: () => ({
    menu: false,
    date: undefined,
  }),

  computed: {
    currentTime() {
      const currentTime = DateTime.local()
        .setLocale('Europe/Paris')
        .endOf('month')

      return currentTime
    },
    datePickerStartAt() {
      if (datePickerStartAt) {
        return DateTime.fromFormat(datePickerStartAt, 'yyyy-MM')
      }
      return null
    },
  },

  methods: {
    allowedDates(date) {
      const testedDate = DateTime.fromFormat(date, 'yyyy-MM')
      const isBeforeNow = testedDate <= this.currentTime

      if (this.datePickerStartAt) {
        const isAfterConfigDate = testedDate >= this.datePickerStartAt

        return isBeforeNow && isAfterConfigDate
      }
      return isBeforeNow
    },
    handleSave(date) {
      // Set the payload for get content action
      // component date format is XXXX-YY
      if (date) {
        const payload = {
          month: date.substring(5, 7), // target last 2 numbers
          year: date.substring(0, 4), // target first 4 numbers
        }
        // Save the date for UI component
        this.$refs.menu.save(date)

        // Save the date in store for following requests
        this.$store.dispatch('filters/saveDateQuery', payload)
      } else {
        // Reset the dates to get all content
        this.$store.dispatch('filters/saveDateQuery', {
          month: undefined,
          year: undefined,
        })

        // Reset the UI component data
        this.date = undefined
      }

      // Close the datepicker whatever
      this.menu = false
    },
  },
}
</script>
