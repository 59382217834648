<template>
  <div
    :class="[absolute ? 'progress-absolute' : 'progress-relative']"
    class="w-player-progress-slider"
  >
    <v-slider
      :value="percent"
      :height="absolute ? 20 : 46"
      color="primary"
      step="0.1"
      thumb-label
      thumb-size="52"
      @end="handleEnd"
      @start="handleStart"
      @change="handleChange"
    >
      <template slot="thumb-label" slot-scope="props">
        <span>
          {{ durationHumanized(props.value) }}
        </span>
      </template>
    </v-slider>
  </div>
</template>

<script>
import spoke from '~/utils/spoke'
import formatTime from '~/mixins/formatTime'

export default {
  mixins: [formatTime],
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    percent: 0,
  }),

  mounted() {
    this._shouldUpdateSlider = true
    this._wasPlayingBeforeSlide = false
    spoke.mediaplayer.on('timeupdate', ({ percent }) => {
      if (this._shouldUpdateSlider) {
        this.percent = (percent * 100).toFixed(3)
      }
    })
  },

  methods: {
    durationHumanized(value) {
      if (!spoke.mediaplayer.episode) {
        return '0:00'
      }

      const seconds =
        (spoke.mediaplayer.episode.computedAudioDuration * value) / 100

      return this.formatTime(seconds)
    },
    handleChange(value) {
      this._shouldUpdateSlider = false
      if (this.percent !== value) {
        this.percent = value

        spoke.mediaplayer.setPercents(value)

        setTimeout(() => {
          this._shouldUpdateSlider = true
        }, 0)
      }
    },
    handleStart() {
      this._wasPlayingBeforeSlide = spoke.mediaplayer.isPlaying
      spoke.mediaplayer.pause()
    },
    handleEnd(value) {
      if (this.percent !== value) {
        this.percent = value
        spoke.mediaplayer.setPercents(value)

        if (this._wasPlayingBeforeSlide) {
          spoke.mediaplayer.play()
        }

        setTimeout(() => {
          this._shouldUpdateSlider = true
        }, 0)
      }
    },
  },
}
</script>

<style lang="scss">
.progress {
  &-relative {
    position: relative;
    width: 200px;
  }
  &-absolute {
    position: absolute;
    top: -26px;
    left: 0;
    right: 0;
    z-index: 9;
  }
}
.v-slider {
  .v-input {
    &__slot {
      margin-bottom: 0;
    }
  }
  &__thumb {
    width: 24px;
    height: 24px;
  }
}
</style>
