<template>
  <v-progress-linear
    v-model="fullValue"
    :indeterminate="!full"
    :class="[absolute ? 'w-loader-absolute' : 'w-loader-relative']"
    :height="2"
  />
</template>

<script>
export default {
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fullValue: 100,
  }),
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  margin: 0;
}

.w-loader {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
}
</style>
