<template>
  <v-container
    class="page--index mt-2"
    :class="{
      'pa-0': $voicer.isDesktopOrTablet,
      'pb-5': $voicer.isDesktopOrTablet,
    }"
    grid-list-md
  >
    <w-block name="heading" />
    <v-layout row wrap>
      <v-flex xs12>
        <w-filters v-if="pageOptions.filter" />
      </v-flex>
      <w-voicy :options="pageOptions" :load-more="handleLoadMoreContents" />
    </v-layout>
  </v-container>
</template>

<script>
import PageMixin from '~/mixins/page'

export default {
  name: 'Home',
  transition: 'page',
  mixins: [PageMixin],
}
</script>
