<template>
  <v-list two-line class="w-comments-threads-list">
    <transition-group name="list">
      <template v-for="item in threads">
        <w-list-item
          :key="item.__uid__"
          :item="item"
          :clickable="hasSubResponses"
          auto-reduce
          @open="handleOpen(item.id)"
        />
      </template>
    </transition-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WListItem from './WListItem'

export default {
  components: {
    WListItem,
  },

  props: {
    messager: {
      type: Object,
      default: () => ({}),
    },
    threads: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  computed: {
    hasSubResponses() {
      return this.messagerOptions.subcomments
    },
    ...mapGetters({
      messagerOptions: 'comments/currentOptions',
    }),
  },

  methods: {
    ...mapActions({
      openResponses: 'comments/toggleCommentType',
    }),

    handleOpen(parentId) {
      this.openResponses(parentId)
    },
  },
}
</script>

<style lang="scss">
.w-comments-threads-list {
  .list-enter-active,
  .list-leave-active {
    transition: all 1s;
  }
  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
  .list-move {
    transition: transform 1s;
  }
}
</style>
