<template>
  <v-bottom-nav
    :active.sync="currentModeIndex"
    :value="isVisibleBottomNavigation"
    class="hidden-sm-and-up"
    app
    fixed
  >
    <v-btn
      v-for="(route, index) in visiblesRoutes"
      :key="index"
      :value="index"
      flat
      nuxt
      @click="handleSelectRoute(route.router)"
    >
      <span>{{ route.title($i18n.locale) }}</span>
      <v-icon v-if="route.icon" v-bind="route.iconAttrs">
        {{ route.icon }}
      </v-icon>
      <v-img
        v-else-if="route.image"
        :src="route.image.src"
        v-bind="route.image.attrs"
        class="mb-1"
      />
    </v-btn>
  </v-bottom-nav>
</template>

<script>
import handleRoutes from '~/mixins/handleRoutes'

export default {
  mixins: [handleRoutes],
}
</script>

<style lang="scss" scoped>
.v-bottom-nav {
  z-index: 4;
}
</style>
