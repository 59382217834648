<template>
  <w-layout-modal
    :class="{
      'w-page-password_reset_confirmation--mobile':
        $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-password_reset_confirmation"
  >
    <template v-slot:content>
      <w-form-new-password @submit="handleSubmit" />
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import WFormNewPassword from '~/components/WFormNewPassword'

export default {
  name: 'PasswordResetUpdate',
  layout: 'minimal',
  components: {
    WFormNewPassword,
  },

  methods: {
    async handleSubmit(payload) {
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/resetPassword', payload)
      } catch (error) {
        // catched in store
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
