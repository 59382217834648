<i18n>
  {
    "en": {
      "title": "Privacy settings",
      "cookie_session": "Session cookie",
      "cookie_session_desc": "Mandatory cookie, necessary for a proper functioning of the site",
      "metrics_trackers": "Usage data collect",
      "metrics_trackers_desc": "Allows you to collect metrics on the use of the application. Helps us to improve the general functioning of the application.",
      "metrics_trackers_notify_enabled": "Tracking trackers have been activated",
      "metrics_trackers_notify_disabled": "Tracking trackers have been disabled",
      "home": "Home"
    },
    "fr": {
      "title": "Paramètres de confidentialité",
      "cookie_session": "Cookie de session",
      "cookie_session_desc": "Cookie obligatoire, nécessaire au bon fonctionnement du site",
      "metrics_trackers": "Collecte des données d'usage",
      "metrics_trackers_desc": "Permet de collecter des métriques sur l'usage de l'application. Nous aide à améliorer le fonctionnement général de l'application.",
      "metrics_trackers_notify_enabled": "Les trackers de suivis ont bien été activé",
      "metrics_trackers_notify_disabled": "Les trackers de suivis ont bien été désactivé",
      "home": "Retour à l'accueil"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-settings--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    :title="$t('title')"
    class="w-page-settings"
  >
    <template v-slot:content>
      <v-list three-line>
        <v-list-tile>
          <v-list-tile-action>
            <v-switch v-model="optPrivacySession" disabled />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="$t('cookie_session')" />
            <v-list-tile-sub-title v-text="$t('cookie_session_desc')" />
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-action>
            <v-switch v-model="optPrivacyMetrics" color="primary" />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="$t('metrics_trackers')" />
            <v-list-tile-sub-title v-text="$t('metrics_trackers_desc')" />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </template>
    <template v-slot:actions>
      <v-spacer />
      <v-btn color="primary" depressed nuxt to="/" v-text="$t('home')" />
    </template>
  </w-layout-modal>
</template>

<script>
import notifier from '~/utils/notifier'

export default {
  layout: 'minimal',

  name: 'Settings',

  transition: 'page',

  components: {},

  data: () => ({
    optPrivacySession: true,
  }),

  computed: {
    optPrivacyMetrics: {
      get() {
        return this.$store.state.root.optEnableMetricsTrackers
      },
      set(v) {
        const notify = notifier(this.$store.dispatch)

        if (v) {
          this.$store.dispatch('root/enableMetricsTrackers')
          notify('success', this.$t('metrics_trackers_notify_enabled'))
        } else {
          this.$store.dispatch('root/disableMetricsTrackers')
          notify('success', this.$t('metrics_trackers_notify_disabled'))
        }
      },
    },
  },
}
</script>

<style lang="scss">
.w-page-settings {
  .v-list--three-line .v-list__tile {
    height: auto !important;
    min-height: 88px; // because
  }
}
</style>
