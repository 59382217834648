<script>
import WSSOComponents from '~/components/WSSO'

export default {
  components: {
    ...WSSOComponents,
  },
  props: {
    providers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  render(h) {
    return h(
      'div',
      { class: 'w-auth-providers' },
      this.providers.filter(Boolean).map((provider) => {
        let name

        if (typeof provider === 'string') {
          name = provider
        } else if (Array.isArray(provider)) {
          name = provider[0]
        } else {
          throw new TypeError(
            `Bad parameter, you should pass a String or an Array`
          )
        }

        if (!WSSOComponents[name]) {
          throw new Error(
            `Unknown sso provider component ${name}, have you created the component into the components/WSSO folder ?`
          )
        }

        return h(WSSOComponents[name], {
          on: {
            select: () => {
              this.$emit('select', provider)
            },
            success: (e) => {
              this.$emit('success', e)
            },
            reject: (e) => {
              this.$emit('reject', e)
            },
          },
        })
      })
    )
  },
}
</script>

<style lang="scss" scoped>
.w-auth-providers > * {
  margin-bottom: 10px;
}
</style>
