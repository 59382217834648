<i18n>
{
  "en": {
    "filter_by": "Filter by {filterName}"
  },
  "fr": {
    "filter_by": "Filtrer par {filterName}"
  }
}
</i18n>

<template>
  <v-select
    ref="dropdown"
    v-model="selectedTags"
    :items="tags"
    :label="$t('filter_by', { filterName })"
    :menu-props="{
      closeOnContentClick: true,
    }"
    prepend-inner-icon="filter_list"
    item-text="name"
    item-value="id"
    flat
    hide-details
    multiple
    chips
    solo
  >
    <template slot-scope="data">
      <v-chip :selected="data.selected">
        {{ data.item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { $data } from '~/utils/spoke'

export default {
  props: {
    // Needed to name the UI component
    filter: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    selectedTags: [],
  }),

  computed: {
    tags() {
      return $data(this.filter, 'tags', [])
    },

    filterName() {
      return $data(this.filter, 'name', '').toLowerCase()
    },
  },

  watch: {
    selectedTags(n) {
      this.handleInput(this.filterName, n)
    },
  },

  methods: {
    handleInput(filterSlug, selectedTags) {
      // Set the payload for get content action
      const payload = {
        category: filterSlug,
        tags: selectedTags,
      }

      // Save the date in store for following requests
      this.$store.dispatch('filters/saveTagQuery', payload)
    },
  },
}
</script>
