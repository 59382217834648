<i18n>
{
  "en": {
    "title": "Home"
  },
  "fr": {
    "title": "Accueil"
  }
}
</i18n>

<template>
  <a nuxt href="/" class="w-logo" :style="rootStyle">
    <img :src="logoPath" :alt="$t('title')" :width="width" :height="height" />
  </a>
</template>

<script>
import commons from '~/components/WLogo/commons'

export default {
  mixins: [commons],
  computed: {
    width() {
      return this.$attrs.size || 32
    },
    height() {
      return this.$attrs.size || 32
    },
    logoPath() {
      const iconFileName = this.$voicer.getConfig('metadatas.iconFileName')

      if (typeof iconFileName !== 'string') {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            `Invalid iconFileName type (expecting a string), passed:`,
            iconFileName
          )
        }

        return '/icon.png'
      } else if (iconFileName.charAt(0) !== '/') {
        return '/' + iconFileName
      }

      return iconFileName
    },
    rootStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-logo {
  display: inline-block;
}
</style>
