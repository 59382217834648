<i18n>
  {
    "en": {
      "account_create": "Activate your account",
      "access": "Access to {to}",
      "input_code": "Enter the action code received by email",
      "give_code": "Please enter the 6 digits received by email",
      "submit": "Active my account"
    },
    "fr": {
      "account_create": "Activer votre compte",
      "access": "Accéder à {to}",
      "input_code": "Entrer le code de validation reçu par email",
      "give_code": "Merci de rentrer les 6 chiffres reçus par email",
      "submit": "Activer mon compte"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :loading="isLoading"
    :class="{
      'w-page-register-active--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-register-active"
  >
    <template v-slot:content>
      <div class="w-page-register-active__heading pa-2">
        <div class="w-page-register-active__heading__tile">
          <h2 class="title">
            {{ $t('account_create') }}
          </h2>
          <h3 class="body-2 mt-2">
            {{ $t('access', { to: $metadatas.appName }) }}
          </h3>
        </div>
        <div class="w-page-register-active__heading__actions"></div>
      </div>
      <v-layout wrap class="mt-3">
        <v-flex class="w-register__flex" xs12>
          <v-text-field
            v-model="code"
            :rules="[(v) => v.length === 6 || $t(`give_code`)]"
            :label="$t('input_code')"
            autofocus
            type="number"
            required
            outline
          />
        </v-flex>
        <v-flex class="d-flex justify-center" xs12>
          <v-btn
            color="primary"
            depressed
            type="submit"
            large
            @click="handleSubmit"
            v-text="$t('submit')"
          />
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  layout: 'minimal',

  name: 'Register',

  components: {},

  data: () => ({
    isLoading: false,
    code: 'code',
  }),

  computed: {
    ...mapState({
      canRegister: (state) => state.root.site.optRegister === true,
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },

  methods: {
    ...mapActions({
      activateAccount: 'auth/activateAccount',
    }),
    async handleSubmit() {
      this.isLoading = true
      try {
        await this.activateAccount({
          uid: this.$route.query.uid,
          code: this.code,
        })
        this.$router.replace('/')
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
.w-page-register__heading {
  margin-bottom: 20px;
}
</style>
