<i18n>
{
  "fr": {
    "see_more": "Show me more"
  },
  "fr": {
    "see_more": "En voir plus"
  }
}
</i18n>

<template>
  <v-container fluid class="pa-0">
    <w-div-loader v-if="isLoading || isLoadingMore" />
    <portal to="before-container">
      <div
        v-if="hasHighlightedVoice"
        class="d-flex justify-center"
        :class="{ 'mt-0': $voicer.isDesktop, 'mb-3': $voicer.isMobile }"
      >
        <v-container class="pa-0 ma-0">
          <w-voicy-card
            :card-options="{
              flat: true,
              tile: true,
            }"
            :content="highlightedVoice"
            highlighted
          />
        </v-container>
      </div>
    </portal>
    <v-layout row wrap class="pa-1">
      <v-flex
        v-if="
          isLoading === false && isLoadingMore === false && voices.length === 0
        "
        lg12
      >
        <slot name="empty" />
      </v-flex>
      <v-flex v-for="voice in voices" :key="voice.__uid__" xs12 sm6 md4 lg4>
        <w-voicy-card :content="voice" />
      </v-flex>
    </v-layout>
    <div class="w-voicy__list__bottom">
      <v-btn
        v-if="hasMore"
        :loading="isLoading || isLoadingMore"
        block
        color="primary"
        depressed
        large
        @click="handleMore"
      >
        {{ $t('see_more') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import WDivLoader from '~/components/WDivLoader'
import WVoicyCard from '~/components/WVoicyCard'

export default {
  components: {
    WDivLoader,
    WVoicyCard,
  },

  props: {
    contents: {
      type: Array,
      required: true,
      default: () => [],
    },

    hasMore: {
      type: Boolean,
      required: true,
      default: () => false,
    },

    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    loadMore: {
      type: Function,
      required: true,
      default: (_) => _,
    },
  },

  data: () => ({
    isLoadingMore: false,
  }),

  computed: {
    hasHighlightedVoice() {
      return this.highlighted && this.contents.length > 0
    },
    highlightedVoice() {
      return this.contents[0]
    },
    voices() {
      return this.contents
    },
  },

  methods: {
    async handleMore() {
      this.isLoadingMore = true

      try {
        await this.loadMore()
      } catch (e) {
        this.$voicer.captureException(e)
      } finally {
        this.isLoadingMore = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-voicy {
  &__list {
    &__bottom {
      margin-top: 14px;
      padding-top: 14px;

      button {
        max-width: 280px;
        margin: 0 auto;
      }
    }
  }
}
</style>
