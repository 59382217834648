<template>
  <v-btn icon @click="handleToggle">
    <v-icon>chat</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: mapGetters({
    isVisible: 'comments/isVisible',
  }),

  methods: {
    ...mapActions({
      handleToggle: 'comments/toggleCommentsVisibility',
    }),
  },
}
</script>
