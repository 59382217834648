<template>
  <div
    :class="{
      ['w-comments-dialog--mobile']: $vuetify.breakpoint.xsOnly,
      ['w-comments-dialog--desktop']: $vuetify.breakpoint.smAndUp,
    }"
    class="w-comments-dialog"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      attach=".w-comments-dialog"
      width="500"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <w-comments-modal @handleClose="handleToggle" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import WCommentsModal from '~/components/WCommentsModal'

export default {
  components: {
    WCommentsModal,
  },

  computed: mapGetters({
    isVisible: 'comments/isVisible',
  }),

  methods: {
    ...mapActions({
      handleToggle: 'comments/toggleCommentsVisibility',
    }),
  },
}
</script>

<style lang="scss">
.w-comments-dialog {
  &--desktop {
    .v-dialog__content {
      display: block;
      height: auto;
      width: auto;

      .v-dialog {
        position: fixed;
        margin: 0;
        bottom: 80px;
        right: 16px;
      }
    }
  }
}
</style>
