<i18n>
  {
    "en": {
      "close": "Close"
    },
    "fr": {
      "close": "Fermer"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-data--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-data"
    size="large"
    no-heading
    fullsize
  >
    <template v-slot:before-content>
      <div class="w-page-data__heading">
        <div class="w-page-data__heading__metadatas">
          <w-logo size="24" />
          <h1 class="subheading">
            {{ $metadatas.appName }}
          </h1>
        </div>
        <div class="w-page-data__heading__actions">
          <v-btn color="primary" depressed large @click="handleClose">
            {{ $t('close') }}
          </v-btn>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="w-markdown w-markdown--page" v-html="content" />
    </template>
  </w-layout-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  page: 'Data',
  layout: 'minimal',
  transition: 'page',
  components: {},

  asyncData({ params, error }) {
    try {
      const data = require(`~/static/contents/${params.id}.md`)

      return {
        content: data.default,
      }
    } catch (e) {
      error({ statusCode: 404 })
    }
  },

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },

  mounted() {
    this.$store.dispatch('root/setControlledRGPD', true)
  },

  methods: {
    handleClose() {
      const rgpdPublicRoutes = ['/data/personal-data', '/data/terms-of-service']
      this.$store.dispatch('root/routerGoBack')

      if (rgpdPublicRoutes.includes(this.$route.fullPath)) {
        this.$store.dispatch('root/setControlledRGPD', false)
      }
    },
  },
}
</script>

<style lang="scss">
.w-page-data {
  &__heading {
    padding: 24px;
    padding-bottom: 16px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__heading__metadatas {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    h1 {
      margin-left: 0.25em;
    }
  }

  &__footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .w-markdown--page {
    height: calc(100vh - 204px);
    overflow: hidden;
    overflow-y: auto;
  }

  .flexcard {
    border: 1px #ddd solid;
  }

  &--mobile {
    .flexcard {
      border: 0;
      border-top: 1px #ddd solid;
    }
  }

  .text {
    padding: 15px;
    font-size: 1.1em;
  }
}
</style>
