<template>
  <v-snackbar
    v-model="isVisible"
    :color="color"
    :multi-line="$vuetify.breakpoint.xsOnly"
    auto-height
    top
    right
  >
    <div v-html="message" />
    <v-btn dark flat @click="handleClose">
      <v-icon> close </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      message: (state) => state.notifier.message,
      status: (state) => state.notifier.status,
      notifierIsVisible: (state) => state.notifier.isVisible,
    }),

    isVisible: {
      get() {
        return this.notifierIsVisible
      },

      set(v) {
        if (v) {
          return this.handleOpen()
        } else {
          return this.handleClose()
        }
      },
    },

    color() {
      switch (this.status) {
        default:
          return 'primary'
        case 'error':
          return 'error'
        case 'success':
          return 'success'
        case 'warn':
        case 'warning':
          return 'yellow darken-2'
      }
    },
  },

  methods: {
    ...mapActions({
      handleClose: 'notifier/close',
      handleOpen: 'notifier/open',
    }),
  },
}
</script>
