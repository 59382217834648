<template>
  <v-container
    class="page--listen mt-2"
    :class="{
      'pa-0': $voicer.isDesktopOrTablet,
      'pb-5': $voicer.isDesktopOrTablet,
    }"
  >
    <v-layout row wrap>
      <w-player />
    </v-layout>
  </v-container>
</template>

<script>
import pageHead from '~/mixins/pageHead'
import WPlayer from '~/components/WPlayer'

export default {
  page: 'listen',
  components: {
    WPlayer,
  },

  mixins: [pageHead],

  mounted() {
    if (this.$voicer.isDesktop === false) {
      this.$store.dispatch('player/closePersistantPlayer')
    }
  },
}
</script>
