<script>
import Blocks from '~/blocks'

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  render(h) {
    if (!Blocks[this.name]) {
      throw new Error(
        `Undefined block ${this.name}: check if file exists in ~/blocks`
      )
    }

    if (this.$voicer.getConfig('enableExperimentalBlocks', false) === true) {
      return h(Blocks[this.name].default)
    }

    return null
  },
}
</script>
