var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"w-voicy-card--default transparent",attrs:{"flat":""},on:{"click":_vm.handleSelect}},'v-card',_vm.__cardOptions,false),[_c('v-img',{attrs:{"lazy-src":_vm.cardImg.lazySrc,"src":_vm.cardImg.cdnSrc,"aspect-ratio":_vm.$voicer.getThemeConfigProperty('component.card.aspectRatio', 800 / 450)}},[_c('w-button-play',{staticClass:"transition-fast-in-fast-out",attrs:{"content":_vm.content,"props":{
        color: 'white',
        light: _vm.$voicer.isDarkTheme,
        absolute: true,
        fab: true,
        bottom: true,
        right: true,
      }},on:{"click":function($event){$event.stopPropagation();}}}),_vm._v(" "),(_vm.isLive)?_c('span',{staticClass:"live-label red caption white--text"},[_vm._v("\n      "+_vm._s(_vm.$t('live'))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('v-card-actions',{staticClass:"grey--text text--darken-1",class:( _obj = {}, _obj['mt-2'] = true, _obj['mt-4'] = _vm.$voicer.isIE, _obj )},[_c('w-card-actions',{attrs:{"content":_vm.content}})],1),_vm._v(" "),_c('div',{staticClass:"v-card__content"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"subheading font-weight-medium"},[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),(_vm.$voicer.getConfig('showCardDescription'))?_c('v-card-title',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.summary))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }