<i18n>
{
  "en": {
    "connection": "Connection",
    "authentication_failed": "Authentication failed"
  },
  "fr": {
    "connection": "Connexion",
    "authentication_failed": "Impossible de vous authentifier"
  }
}
</i18n>

<template>
  <v-btn
    class="text-uppercase font-weight-medium text-button"
    color="primary"
    block
    large
    solo
    @click="startAuthFlow"
  >
    {{ config.buttonText }}
  </v-btn>
</template>

<script>
import { getSSOProvider } from '~/utils/voicer'

export default {
  computed: {
    config() {
      return getSSOProvider('WSSOKeycloak')[1]
    },
  },

  methods: {
    startAuthFlow() {
      const { realm, clientId } = this.config
      window.location.href = `https://authk.brocoli.io/auth/realms/${realm}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
        window.location.origin + '/login/redirect'
      )}&response_type=code`
    },
  },
}
</script>

<style lang="scss">
.fusion {
  background: red;
  padding: 10px;
  border: 1px solid black;
}
</style>
