<i18n>
{
  "en": {
    "id": "Login",
    "INPUT_PLACEHOLDER_PHONE": "Phone number",
    "INPUT_PLACEHOLDER_EMAIL": "adress@email.com",
    "INPUT_HINT_FP_EMAIL": "The email address you provided for your account",
    "INPUT_HINT_FP_PHONE": "The phone number you provided for your account",
    "INPUT_HINT_PHONE": "Your phone number",
    "INPUT_HINT_EMAIL": "Your professional email address",
    "id_required": "Login Required",
    "id_format": "You should provide a valid email address or phone number",
    "id_format_email": "Your email address should be formatted like address@email.com",
    "id_format_phone": "The login should be a valid phone number",
    "invalid_id": "Invalid login"
  },
  "fr": {
    "id": "Identifiant",
    "INPUT_PLACEHOLDER_PHONE": "Numéro de téléphone",
    "INPUT_PLACEHOLDER_EMAIL": "adresse@email.com",
    "INPUT_HINT_FP_EMAIL": "Indiquez l'adresse mail que vous avez renseignée lors de votre inscription",
    "INPUT_HINT_FP_PHONE": "Indiquez le numéro de téléphone que vous avez renseignée lors de votre inscription",
    "INPUT_HINT_PHONE": "Votre numéro de téléphone",
    "INPUT_HINT_EMAIL": "Votre adresse mail professionnelle",
    "id_required": "Vous devez renseigner un identifiant",
    "id_format": "Vous devez renseigner une adresse email ou un numéro de téléphone valide",
    "id_format_email": "Votre adresse mail doit être de type example@mail.com",
    "id_format_phone": "Le login doit correspondre à un numéro de téléphone",
    "invalid_id": "Identifiant invalide"
  }
}
</i18n>

<template>
  <v-text-field
    ref="login"
    v-model="model"
    class="w-form-input-login"
    v-bind="props"
    :rules="loginRules"
    :hint="inputHint"
    :name="{ email: forgotPassword }"
    :type="inputType"
    :placeholder="inputPlaceHolder"
    :autocomplete="inputType"
    :label="$t('id')"
    autofocus
    outline
    required
    small
    @keyup.enter="$emit('enter')"
  />
</template>

<script>
import { getConfig } from '~/utils/voicer'

const checkEmailFormat = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return pattern.test(value)
}

const checkPhoneFormat = (value) => {
  // eslint-disable-next-line
  const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

  return pattern.test(value)
}

const authPatterns = getConfig('authLocaleLoginFormat', ['email'])

const isEmail = authPatterns.includes('email')
const isPhone = authPatterns.includes('phone')
const isPhoneOrEmail = isEmail && isPhone

export default {
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
    forgotPassword: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loginRules: [
        (v) => !!v || this.$t('id_required'),
        (v) => {
          if (isPhoneOrEmail) {
            const validEmail = checkEmailFormat(v)
            const validPhone = checkPhoneFormat(v)

            if (!validEmail && !validPhone) {
              return this.$t(`id_format`)
            }
            return true
          } else if (isEmail) {
            return checkEmailFormat(v) || this.$t('id_format_email')
          } else if (isPhone) {
            return checkPhoneFormat(v) || this.$t(`id_format_phone`)
          } else {
            return true
          }
        },
        ...authPatterns.map((format) => {
          if (format instanceof RegExp) {
            return (v) => format.test(v) || this.$t(`invalid_id`)
          }
          // noop if invalid entry
          return (_) => true
        }),
      ],
    }
  },

  computed: {
    model: {
      set(v) {
        this.$emit('input', v)
      },
      get() {
        return this.value
      },
    },
    inputType() {
      if (isPhoneOrEmail) {
        return 'text'
      } else if (isPhone) {
        return 'tel'
      } else if (isEmail) {
        return 'email'
      }

      return 'text'
    },
    inputPlaceHolder() {
      return isPhone
        ? this.$t('INPUT_PLACEHOLDER_PHONE')
        : this.$t('INPUT_PLACEHOLDER_EMAIL')
    },
    inputHint() {
      return this.forgotPassword
        ? isPhone
          ? this.$t('INPUT_HINT_FP_EMAIL')
          : this.$t('INPUT_HINT_FP_PHONE')
        : isPhone
        ? this.$t('INPUT_HINT_PHONE')
        : this.$t('INPUT_HINT_EMAIL')
    },
  },
}
</script>

<style lang="scss">
.w-form-input-login {
  .v-text-field__details {
    display: none;
  }
}
</style>
