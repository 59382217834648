<i18n>
{
  "en": {
    "answer": "Answer to {name}",
    "new_comment": "New comment"
  },
  "fr": {
    "answer": "Répondre à {name}",
    "new_comment": "Nouveau commentaire"
  }
}
</i18n>

<template>
  <v-form
    ref="form"
    v-model="valid"
    class="w-comments-post fullwidth"
    lazy-validation
    @submit.prevent="handlePost"
  >
    <v-layout>
      <v-flex xs12 class="mr-2 theme--light v-toolbar">
        <v-textarea v-model="form.input" solo rows="1" hide-details auto-grow>
          <template v-slot:label>
            <span v-if="parentId">{{
              $t('answer', { name: currentThread.poster.name })
            }}</span>
            <span v-else>{{ $t('new_comment') }}</span>
          </template>
        </v-textarea>
      </v-flex>
      <v-flex>
        <v-btn icon large color="primary" type="submit">
          <v-icon>send</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    parentId: {
      type: String,
      default: null,
    },

    currentThread: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  data: () => ({
    valid: true,
    form: {
      input: '',
    },
  }),

  methods: {
    ...mapActions({
      postComment: 'comments/postComment',
    }),

    handlePost() {
      if (this.form.input) {
        // Get the result from API
        this.postComment(this.form.input)

        // Delete the field input
        this.$refs.form.reset()
      }
    },
  },
}
</script>

<style lang="scss">
.w-comments-post {
  .v-textarea {
    textarea {
      margin-top: 6px;
      max-height: 80px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.fullwidth {
  width: 100%;
}
</style>
