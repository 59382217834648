<i18n>
{
  "en": {
    "sorry": "Sorry,",
    "no_result": "This query gave not motaching content.",
    "go_back_home": "Back to home page",
    "nothing": "There is nothing here, but one day there will"
  },
  "fr": {
    "sorry": "Désolé,",
    "no_result": "Cette recherche ne donne aucun résultat.",
    "go_back_home": "Retourner à la page d'accueil",
    "nothing": "Il n'y a rien ici, mais ça va venir"
  }
}
</i18n>

<template>
  <w-list
    :contents="contents"
    :has-more="hasMore"
    :highlighted="highlighted"
    :is-loading="isLoading"
    :load-more="loadMore"
  >
    <template v-slot:empty>
      <w-empty class="pa-5">
        <h3 class="title">
          {{ $t('sorry') }}
        </h3>
        <div v-if="currentPage === 'search'">
          <p class="body-2">
            {{ $t('no_result') }}
          </p>
          <nuxt-link to="/">
            {{ $t('go_back_home') }}
          </nuxt-link>
        </div>
        <div v-else>
          <p class="body-2">
            {{ $t('nothing') }}
          </p>
        </div>
      </w-empty>
    </template>
  </w-list>
</template>

<script>
import { mapGetters } from 'vuex'

import WList from './WList'
import WEmpty from '~/components/WEmpty'

export default {
  components: {
    WEmpty,
    WList,
  },

  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loadMore: {
      type: Function,
      required: false,
      default: (_) => _,
    },
  },

  computed: {
    ...mapGetters({
      contents: 'contents/contents',
      currentPage: 'root/currentPage',
      isLoading: 'contents/isLoading',
      hasMore: 'contents/hasMore',
    }),
    highlighted() {
      if (typeof this.options.highlighted === 'function') {
        return this.options.highlighted(this.$voicer)
      }
      // temp fix
      // highlighted content cause a weird issue on ie11
      return this.options.highlighted && this.$voicer.isIE === false
    },
  },
}
</script>
