<script>
export default {
  name: 'WItemSummary',
  functional: true,
  props: {
    isTooImportant: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: String,
      default: '',
    },
  },
  render(h, { props }) {
    return h(
      'span',
      {
        class: {
          'body-2': true,
          'w-item-summary': true,
        },
      },
      [
        h(
          'span',
          {
            class: {
              'w-item-summary--text': true,
            },
          },
          props.summary
        ),
        props.isTooImportant &&
          h(
            'span',
            {
              class: {
                'w-item-summary--more': true,
                'grey--text': true,
                'text--lighten-1': true,
              },
            },
            'en voir plus'
          ),
      ].filter(Boolean)
    )
  },
}
</script>

<style lang="scss">
span.w-item-summary {
  &--text {
    white-space: pre-wrap;
    word-break: break-word;
    text-overflow: unset;
    overflow: hidden;
  }
  &--more {
    padding-top: 3px;
    font-size: 13px;
    font-weight: 500;
  }
}

span > span > * {
  display: none;
}
</style>
