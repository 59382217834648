<i18n>
{
  "en": {
    "cookie_policy": "Cookie policy",
    "cookie_allow_line_1": "By continuing browsing this website, you accept cookie usage for to improve",
    "cookie_allow_line_2": "the site quality, to make statistics, analyse your navigation and measure audience.",
    "accept": "Accept",
    "understood": "Understood",
    "refuse": "Refuse"
  },
  "fr": {
    "cookie_policy": "Politique de cookies",
    "cookie_allow_line": "En cliquant sur ACCEPTER ou en poursuivant la navigation, vous reconnaissez avoir pris connaissance et accepter sans réserve les {cgv_link}, la {pol_link} et l’utilisation des cookies nécessaires au bon fonctionnement de l’application web",
    "cgv_link": "Conditions Générales d'Utilisation",
    "pol_link": "Politique de Données Personnelles",
    "accept": "Accepter",
    "understood": "Compris",
    "refuse": "Refuser"
  }
}
</i18n>

<template>
  <div>
    <v-dialog v-model="open" width="420" max-width="100%" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t('cookie_policy') }}
        </v-card-title>
        <v-card-text class="body-1">
          <i18n path="cookie_allow_line" tag="div">
            <template v-slot:cgv_link>
              <nuxt-link to="/data/terms-of-service" v-text="$t('cgv_link')" />
            </template>
            <template v-slot:pol_link>
              <nuxt-link to="/data/personal-data" v-text="$t('pol_link')" />
            </template>
          </i18n>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="handleRefuse">
            {{ $t('refuse') }}
          </v-btn>
          <v-btn depressed color="primary" @click="handleAccept">
            {{ $t('accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import localstore from 'store'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      open(state) {
        return state.root.controlledRGPD === false
      },
    }),
    isControllable() {
      return this.$route.name !== 'data-id'
    },
  },

  watch: {
    '$route.name'(currentName) {
      if (currentName === 'data-id') {
        const hasControlled = localstore.get(
          'acceptedRGPD',
          !localstore.get('refusedRGPD', navigator.doNotTrack === '1')
        )
        this.$store.dispatch('root/setControlledRGPD', hasControlled)
      }
    },
  },

  methods: {
    handleRefuse() {
      this.$store.dispatch('root/disableMetricsTrackers')
    },
    handleAccept() {
      this.$store.dispatch('root/enableMetricsTrackers')
    },
  },
}
</script>
