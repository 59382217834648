<i18n>
{
  "en": {
    "loading": "Loading",
    "app_down": "Application is not responding. Please refresh this application."
  },
  "fr": {
    "loading": "Chargement",
    "app_down": "L'application ne répond pas pour le moment, veuillez actualiser l'application."
  }
}
</i18n>

<template>
  <v-layout
    v-if="loading || __isLoading"
    :class="{
      'w-screen-loader--absolute': absolute,
    }"
    :style="computedStyle"
    fill-height
    align-center
    justify-center
    text-xs-center
    class="w-screen-loader"
  >
    <div v-if="!__isDead">
      <div class="w-screen-loader__text ma-2">
        <v-progress-circular
          :size="size"
          :width="width"
          color="primary"
          indeterminate
        />
        <div class="w-screen-loader__text__title ml-4">
          <w-logo size="24" />
          <div class="ml-4">
            <div class="caption mb-1" v-text="$t('loading')"></div>
            <div class="title">
              {{ $metadatas.appName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text--white">
        {{ $t('app_down') }}
      </p>
    </div>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    ...mapState({
      __isLoading: (state) => state.root.isSpokeConfigured === false,
      __isDead: (state) => state.root.isSpokeDead === true,
    }),
    computedStyle() {
      return {
        backgroundColor: this.$voicer.getConfig(
          'webappThemeColor',
          this.$voicer.isThemeDark() ? '#3b3b3b' : '#fafafa'
        ),
      }
    },
  },
}
</script>

<style lang="scss">
.w-screen-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &--absolute {
    position: absolute;
  }

  &__text {
    display: flex;
    align-items: center;

    &__title {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }
}
</style>
