<i18n>
{
  "en": {
    "give_name": "Please provide your name",
    "name": "Your name",
    "email": "Email address",
    "email_hint": "Your professional email address",
    "password": "Password",
    "password_hint": "The password you need to sign in",
    "password_length": "Your password must be at least 4 characters long",
    "confirm": "Confirm",
    "finish_subscribe": "Finish registration",
    "login_required": "You must provide a login",
    "email_bad_format": "You must provide a valid email address"
  },
  "fr": {
    "give_name": "Veuillez renseigner votre nom",
    "name": "Votre nom",
    "email": "Adresse email",
    "email_hint": "Votre adresse email professionelle",
    "password": "Mot de passe",
    "password_hint": "Le mot de passe qui vous permettra de vous connecter",
    "password_length": "Votre mot de passe doit contenir plus de 4 caractères",
    "confirm": "Confirmer",
    "finish_subscribe": "Terminer l'inscription",
    "login_required": "Vous devez renseigner un identifiant",
    "email_bad_format": "Vous devez renseigner une adresse email valide"
  }
}
</i18n>

<template>
  <v-form v-model="isValid" class="w-register" @submit.prevent>
    <v-layout wrap>
      <v-flex class="w-register__flex" xs12>
        <v-text-field
          v-model="name"
          :rules="[(v) => v.length >= 2 || $t(`give_name`)]"
          autofocus
          type="text"
          :label="$t('name')"
          autocomplete="name"
          required
          outline
        />
      </v-flex>
      <v-flex class="w-register__flex w-register__flex--email" xs12>
        <v-text-field
          v-model="login"
          :rules="emailRules"
          type="email"
          :label="$t('email')"
          :hint="$t('email_hint')"
          autocomplete="email"
          required
          outline
        />
      </v-flex>
      <v-flex class="w-register__flex" xs12>
        <v-text-field
          v-model="password"
          :rules="[(v) => v.length >= 4 || $t(`password_hint`)]"
          type="password"
          :label="$t('password')"
          autocomplete="new-password"
          required
          outline
        />
      </v-flex>
      <v-flex
        class="w-register__flex w-register__flex--actions text-center"
        xs12
      >
        <v-btn
          :disabled="!isValid"
          color="primary"
          depressed
          type="submit"
          large
          @click="handleNext"
          >{{ $t('finish_subscribe') }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

// @todo: duplicate in WInputUsername
const checkEmailFormat = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return pattern.test(value)
}

export default {
  data: () => ({
    isValid: false,
    login: '',
    name: '',
    password: '',
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || this.$t('login_required'),
        (v) => {
          const validEmail = checkEmailFormat(v)

          if (validEmail === false) {
            return this.$t(`email_bad_format`)
          }

          return true
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      register: 'auth/register',
      setSuccess: 'notifier/setSuccess',
      setError: 'notifier/setError',
    }),
    handleNext() {
      const payload = {
        login: this.login,
        password: this.password,
        username: this.name,
      }

      this.register(payload)
        .then((data) => {
          this.$emit('registersuccess', data)
        })
        .catch((error) => {
          this.$emit('registererror', error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.w-register {
  &__flex {
    padding: 8px;
    padding-bottom: 0;

    &--email {
      margin-bottom: 12px;
    }

    &--actions {
      margin-top: 36px;
    }
  }
}
</style>
