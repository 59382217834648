<i18n>
{
  "en": {
    "live": "Live"
  },
  "fr": {
    "live": "Direct"
  }
}
</i18n>

<template>
  <v-card
    class="w-voicy-card--default transparent"
    v-bind="__cardOptions"
    flat
    @click="handleSelect"
  >
    <v-img
      :lazy-src="cardImg.lazySrc"
      :src="cardImg.cdnSrc"
      :aspect-ratio="
        $voicer.getThemeConfigProperty('component.card.aspectRatio', 800 / 450)
      "
    >
      <w-button-play
        :content="content"
        :props="{
          color: 'white',
          light: $voicer.isDarkTheme,
          absolute: true,
          fab: true,
          bottom: true,
          right: true,
        }"
        class="transition-fast-in-fast-out"
        @click.stop
      />
      <span v-if="isLive" class="live-label red caption white--text">
        {{ $t('live') }}
      </span>
    </v-img>
    <v-card-actions
      class="grey--text text--darken-1"
      :class="{
        ['mt-2']: true,
        ['mt-4']: $voicer.isIE,
      }"
    >
      <w-card-actions :content="content" />
    </v-card-actions>
    <div class="v-card__content">
      <v-card-title primary-title>
        <h3 class="subheading font-weight-medium">{{ title }}</h3>
      </v-card-title>
      <v-card-title
        v-if="$voicer.getConfig('showCardDescription')"
        class="body-1"
        >{{ summary }}</v-card-title
      >
    </div>
  </v-card>
</template>

<script>
import VoicyCardMixin from './mixins/common'
import contentInfosMixin from '~/mixins/contentInfos'

export default {
  mixins: [VoicyCardMixin, contentInfosMixin],
}
</script>

<style lang="scss">
.w-voicy-card {
  &--with-cursor {
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;

  .v-responsive.v-image {
    flex-shrink: 1;
    flex-grow: 0;
  }

  .live-label {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 2px 4px;
    border-radius: 3px;
  }

  .w-button-play.v-btn {
    bottom: 16px;
  }

  .v-card {
    display: flex;
    flex-direction: column;

    &__title,
    &__title--primary {
      padding-top: 8px;
    }

    &__content {
      flex-grow: 1;
    }

    &__actions {
      align-items: flex-end;
      flex: 1;
      padding: 0 16px;

      div {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      div:not(:first-child) {
        &::before {
          content: '·';
          display: flex;
          padding: 0 7px;
        }
      }

      .w-button-like {
        button {
          width: 22px;
          height: 22px;
          margin-left: 6px;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
