<template>
  <!-- Read-only progress -->
  <v-progress-linear v-model="percent" height="2" class="ma-0" />
</template>

<script>
import spoke from '~/utils/spoke'

export default {
  data: () => ({
    percent: 0,
  }),

  mounted() {
    spoke.mediaplayer.on('timeupdate', ({ percent }) => {
      this.percent = (percent * 100).toFixed(3)
    })
  },
}
</script>
