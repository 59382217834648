<template>
  <v-tab class="w-wavy" ripple @click.stop="handlePlayWavy(content)">
    <v-avatar color="white" size="64" tile>
      <v-img
        :src="thumbnailImg.cdnSrc"
        :lazy-src="thumbnailImg.lazySrc"
        aspect-ratio="1"
      >
        <v-layout align-center fill-height justify-center>
          <v-progress-circular
            v-if="isLoadingContent"
            indeterminate
            color="white"
          />
          <v-icon v-else-if="isPlayingContent" large> pause </v-icon>
          <v-icon v-else-if="isError" large> error_outline </v-icon>
          <v-icon v-else large> play_arrow </v-icon>
        </v-layout>
      </v-img>
    </v-avatar>
    <div class="w-wavy__content">
      <div class="w-wavy__content__title">
        {{ content.title }}
      </div>
      <div class="w-wavy__content__play">Lancer le direct</div>
    </div>
  </v-tab>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import contentInfosMixin from '~/mixins/contentInfos'
import VoicyCardMixin from '~/components/WVoicyCard/mixins/common'

export default {
  mixins: [VoicyCardMixin, contentInfosMixin],

  props: {
    content: {
      type: Object,
      default: () => this.$spoke.model('content'),
    },
  },

  computed: {
    ...mapState({
      playerContentId: (state) => state.player.content.id,
    }),
    ...mapGetters({
      isError: 'player/isError',
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
    }),
    isLoadingContent() {
      const content = this.content

      return this.isLoading && content.id === this.playerContentId
    },
    isPlayingContent() {
      const content = this.content

      return this.isPlaying && content.id === this.playerContentId
    },
  },
  methods: {
    handlePlayWavy(content) {
      this.$store.dispatch('player/changePlayerContent', {
        content,
        noRedirect: true,
      })
      this.$store.dispatch('player/ctrlPlayer', {
        action: 'play',
        content,
      })
      this.$emit('select', content)
    },
  },
}
</script>

<style lang="scss">
.w-wavy {
  min-width: 210px;
  max-width: 225px;

  .v-image {
    .v-responsive__content {
      position: relative;
    }
    .layout {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, 0.452);
      margin: 0 !important;
    }
  }

  &__content {
    padding-left: 10px;
    text-align: left;
  }

  &__content__play {
    text-transform: initial;
  }
}
</style>
