<template>
  <v-btn v-bind="props" @click="handleComments">
    <slot />
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    props: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  methods: {
    ...mapActions({
      handleComments: 'comments/toggleCommentsVisibility',
    }),
  },
}
</script>
