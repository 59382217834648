<template>
  <w-layout-modal
    :class="{
      'w-page-password_reset_confirmation--mobile':
        $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-password_reset_confirmation"
  >
    <template v-slot:content>
      <div class="body-1">
        Vous allez recevoir d'ici quelques instants un message de confirmation
        sur votre adresse email.<br />
        Cliquez sur le lien présent dans cet email, vous serez ensuite invité à
        renseigner votre nouveau mot de passe.
      </div>
      <div class="body-1 mt-4">
        Vous ne recevez pas de message ? Contactez le support par email,
        <w-support-email-address />.
      </div>
      <div class="mt-4">
        <v-btn nuxt to="/login" depressed color="primary"
          >Revenir sur l'écran de connexion</v-btn
        >
      </div>
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
export default {
  name: 'PasswordResetConfirmation',
  layout: 'minimal',
  components: {},
}
</script>
