import { render, staticRenderFns } from "./WCardActions.vue?vue&type=template&id=9897a770&scoped=true&"
import script from "./WCardActions.vue?vue&type=script&lang=js&"
export * from "./WCardActions.vue?vue&type=script&lang=js&"
import style0 from "./WCardActions.vue?vue&type=style&index=0&id=9897a770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9897a770",
  null
  
)

/* custom blocks */
import block0 from "./WCardActions.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
