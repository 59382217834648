<template>
  <v-layout id="w-filters" row wrap class="pb-0">
    <v-flex
      v-for="(category, index) in categories"
      :key="index"
      xs12
      md4
      class="pa-1"
    >
      <w-filters-datepicker v-if="category.type === 'datepicker'" />
      <w-filters-tags v-else :filter="category" :index="index" />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import WFiltersDatepicker from '~/components/WFiltersDatepicker'
import WFiltersTags from '~/components/WFiltersTags'
import { findPageFromSlug } from '~/utils/voicer'

export default {
  components: {
    WFiltersDatepicker,
    WFiltersTags,
  },

  computed: {
    ...mapState({
      categories(state) {
        const datepicker = {
          type: 'datepicker',
        }

        const pageOptions = findPageFromSlug(this.currentPage, 'options')
        let filters = []

        if (Array.isArray(pageOptions.filter)) {
          const tagFilters = state.filters.categories.filter((category) => {
            return pageOptions.filter.includes(category.slug)
          })

          filters = [datepicker, ...tagFilters]
        } else {
          filters = [datepicker, ...state.filters.categories]
        }

        return [...filters].filter(
          this.$voicer.getConfigStrict('filterVisibleFilters')({
            page: this.currentPage,
            locale: this.$i18n.locale,
          })
        )
      },
      queries: (state) => state.filters.queries,
    }),
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },
}
</script>
