<template>
  <div v-if="hasEpisodes" class="w-player-chapters--mobile">
    <w-player-chapters @play="closePanel" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WPlayerChapters from '~/components/WPlayerChapters'

export default {
  components: {
    WPlayerChapters,
  },

  data: () => ({
    panel: [],
  }),

  computed: {
    ...mapGetters({
      hasEpisodes: 'player/hasEpisodes',
      hasNextEpisode: 'player/hasNextEpisode',
      nextEpisode: 'player/nextEpisode',
    }),
  },

  methods: {
    closePanel() {
      this.panel = []
    },
  },
}
</script>
