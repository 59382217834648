<template>
  <v-menu
    offset-y
    left
    nudge-top="32px"
    nudge-right="-54px"
    :close-on-content-click="false"
    style="box-shadow: none"
    class="w-volume-controller__menu"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon v-if="volume > 0"> volume_up </v-icon>
        <v-icon v-else> volume_off </v-icon>
      </v-btn>
    </template>
    <v-card class="w-volume-controller" flat width="180" elevation="0">
      <v-slider v-model="volume" />
    </v-card>
  </v-menu>
</template>

<script>
import spoke from '~/utils/spoke'

export default {
  data() {
    return {
      volume: 100,
    }
  },
  watch: {
    volume(value) {
      spoke.mediaplayer.setVolume(value / 100)
    },
  },
}
</script>

<style lang="scss">
.w-volume-controller {
  padding: 10px;

  &__menu {
    .v-menu {
      &__content {
        box-shadow: none;
      }
    }
  }

  .v-input {
    &--slider {
      margin-top: 0;
    }

    &__control {
      .v-messages {
        display: none;
      }
    }

    &__slot {
      margin-bottom: 0;
    }
  }
}
</style>
