<i18n>
{
  "en": {
    "pwd_hint": "Your password",
    "pwd_label": "Password",
    "pwd_required": "You must provide a password",
    "pwd_length": "Your password must be at least 4 characters long"
  },
  "fr": {
    "pwd_hint": "Votre mot de passe sécurisé",
    "pwd_label": "Mot de passe",
    "pwd_required": "Vous devez renseigner un mot de passe",
    "pwd_length": "Le mot de passe doit contenir au moins 4 caractères"
  }
}
</i18n>

<template>
  <v-text-field
    ref="password"
    v-model="password"
    v-bind="props"
    :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
    :rules="passwordRules"
    :type="passwordShow ? 'text' : 'password'"
    autocomplete="current-password"
    :hint="$t('pwd_hint')"
    :label="$t('pwd_label')"
    :placeholder="$t('pwd_label')"
    name="current-password"
    outline
    required
    @click:prepend="$emit('click:prepend')"
    @click:append="passwordShow = !passwordShow"
    @keyup.enter="$emit('enter')"
  />
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: '',
      passwordShow: false,
      passwordRules: [
        (v) => !!v || this.$t('pwd_required'),
        (v) => (v && v.length >= 4) || this.$t('pwd_length'),
      ],
    }
  },
  computed: {
    password: {
      set(v) {
        this.$emit('input', v)
      },
      get() {
        return this.value
      },
    },
  },
}
</script>
