<template>
  <div>
    <w-loader
      v-if="isLive"
      :absolute="$vuetify.breakpoint.smAndUp"
      :full="true"
    />
    <w-slider v-else-if="slider" :absolute="$vuetify.breakpoint.smAndUp" />
    <w-progress v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import WLoader from './WLoader'
import WSlider from './WSlider'
import WProgress from './WProgress'

export default {
  components: {
    WLoader,
    WSlider,
    WProgress,
  },
  props: {
    isLive: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    slider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'player/isLoading',
    }),
  },
}
</script>
